import qs from 'qs';

import { API_URL } from '../constants/constants';

const METHODS = ['get', 'post', 'put', 'patch', 'delete'];

// Helper function to format URL
export const formatUrl = (path, options) => {
  if (options?.useExactUrl) {
    return path;
  }

  const adjustedPath = path.startsWith('/') ? path : `/${path}`;
  const baseUrl = API_URL.endsWith('/') ? API_URL.slice(0, -1) : API_URL;
  return `${baseUrl}${adjustedPath}`;
};

const getFetchOptions = (method, data, headers) => {
  const options = {
    method,
    headers: new Headers(headers),
  };

  // https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#simple_requests
  if (method !== 'get' && METHODS.includes(method)) {
    if (options.headers.get('Content-Type') === 'multipart/form-data') {
      /**
       * When we send formData, the browser automatically adds the correct Content-Type which is multipart/form-data.
       * Browser also adds something known as a boundary to this Content-Type which helps server parse the formData we are sending.
       * So we should not set Content-Type manually here and let the browser handle it correctly.
       */
      options.headers.delete('Content-Type');
      options.body = data;
    } else {
      options.body = JSON.stringify(data);
      options.headers.append('Content-Type', 'application/json');
    }
  }
  return options;
};

function parseResponse(response) {
  const contentType = response.headers.get('Content-Type') || '';
  if (contentType.includes('application/json')) {
    return response.json();
  }
  if (contentType.includes('text/')) {
    return response.text();
  }
  return response.blob();
}

class ApiClient {
  constructor() {
    this.headers = {};
    this.abortControllers = new Map();
  }

  static getRequestKey(path, params, qsOptions) {
    // Create a unique key that includes both path and query parameters
    const queryString = params ? `?${qs.stringify(params, qsOptions)}` : '';
    return `${path}${queryString}`;
  }

  createAbortController(path, params, qsOptions) {
    const requestKey = ApiClient.getRequestKey(path, params, qsOptions);
    // Cancel any existing request to the same endpoint with same params
    if (this.abortControllers.has(requestKey)) {
      this.abortControllers.get(requestKey).abort();
    }
    const controller = new AbortController();
    this.abortControllers.set(requestKey, controller);
    return controller;
  }

  cleanupAbortController(path, params, qsOptions) {
    const requestKey = ApiClient.getRequestKey(path, params, qsOptions);
    this.abortControllers.delete(requestKey);
  }

  async makeRequest(
    method,
    path,
    { params, data, headers = {}, keepHeaders, qsOptions, useExactUrl = false } = {}
  ) {
    const url =
      formatUrl(path, { useExactUrl }) +
      (params ? `?${qs.stringify(params, qsOptions)}` : '');

    const controller = this.createAbortController(path, params, qsOptions);
    const fetchOptions = {
      ...getFetchOptions(method, data, { ...this.headers, ...headers }),
      signal: controller.signal,
    };

    let response = {};
    try {
      response = await fetch(url, fetchOptions);
      this.cleanupAbortController(path, params, qsOptions);
    } catch (fetchError) {
      this.cleanupAbortController(path, params, qsOptions);

      // Don't throw error for aborted requests
      if (fetchError.name === 'AbortError') {
        return null;
      }

      // Handle other fetch errors
      const structuredFetchError = new Error(fetchError.message || 'Failed to fetch');
      structuredFetchError.response = {
        body: {
          error: {
            code: fetchError?.message || 'unknown_fetch_error',
          },
        },
      };
      throw structuredFetchError;
    }

    const responseBody = await parseResponse(response);
    if (response.ok) {
      return keepHeaders
        ? { headers: response.headers, body: responseBody, params }
        : responseBody;
    }
    const error = new Error('HTTP error');
    error.response = {
      body: responseBody,
    };
    error.status = response?.status;
    throw error;
  }

  setHeader(key, value) {
    this.headers[key] = value;
  }

  unsetHeader() {
    this.headers = {};
  }
}

// Define HTTP methods
METHODS.forEach(method => {
  ApiClient.prototype[method] = function makeRequest(path, options) {
    return this.makeRequest(method, path, options);
  };
});

export default ApiClient;
